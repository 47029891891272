<template>
  <div class="login-page">
    <section>
      <div class="loginArticle">
        <article class="loginSection">
          <div
            style="display: flex; align-items: center; justify-content: center; "
            class="mb-4"
          >

            <img
              :src="$store.state.logoImageUrl"
              width="200px"
              alt=""
              class="img-fluid "
            />
          </div>
          <div class="form-group">
            <label for="email">email</label>
            <input
              type="text"
              v-model="email"
              @keyup.enter="loginUser"
              name="email"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              v-model="password"
              @keyup.enter="loginUser"
              name="password"
              class="form-control"
            />
            <span
              @click="password.type=password.type=='password'?'text':'password'"
              class="fa fa-fw field-icon toggle-password"
              :class="(password.type=='password')?' fa-eye':' fa-eye-slash'"
            ></span>
          </div>
          <div class="form-group">
            <div
              v-if="isBusy"
              class="d-flex justify-content-center"
            >
              <ScaleOut />
            </div>
            <button
              class="btn btn-block btn-black"
              @click="loginUser"
              v-else
            >
              Login
            </button>
          </div>
          <div class="text-center mt-5">
            <small> &#169; copyright 2022 Everythingirly</small>
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
    import ScaleOut from "@/components/scale-out-component.vue";

    export default {
      components: {
        ScaleOut,
      },

      data() {
        return {
          isBusy: false,
          email: "",
          password: "",
        };
      },

      methods: {
        async loginUser() {
          try {
            this.isBusy = true;
            let request = await this.$http.post("users/login", {
              email: this.email,
              password: this.password,
            });
            if (
              request.data.success &&
              request.data.message == "Login successful"
            ) {
              this.$notify({
                title: "Login Successful",
                message: "You were successfully logged in.",
                type: "success",
              });
                this.$store.commit("LOGIN_USER", request.data);
              if (request.data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(request.data.token));
                // this.$store.commit("LOGIN_USER", request.data);
                this.$router.replace({ path: "/" });
                location.reload(true);
              }
            } else throw "UNEXPECTED_RESPONSE_RECEIVED";
          } catch (error) {
            if (error.message === "Network Error") {
              return this.$notify({
                title: "Connection Failed",
                message:
                  "Unable to Connect. Please check your Internet Connection and try again.",
                type: "error",
              });
            }

            alert(error);
            //If the API returns other status codes besides 2xx, Handle these responses
            if (error.response) {
              if (error.response.data.message === "Invalid email or password") {
                return this.$notify({
                  title: "Invalid",
                  message: "Invalid email or password",
                  type: "warning",
                });
              }
            } else {
              return this.$notify({
                title: "Login Failed",
                message: "Unable to Complete login now. Please try again.",
                type: "error",
              });
            }
          } finally {
            this.isBusy = false;
          }
        },
      },
    };
</script>

<style scoped>
.login-page {
  height: 100vh;
  width: 100%;
  background-color: #EBD6D5;
  background-image: url(../assets/images/4.jpeg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.loginArticle {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginSection {
  width: 40%;
    background-color: #f1d0be;
  padding: 50px;
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.51);
  -webkit-transition: box-shadow 0.5s;
  transition: box-shadow 0.5s;
}

.loginSection:hover {
  -webkit-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 1.5px -1px rgba(0, 0, 0, 0.75);
  transition: 1s;
}
.btn-black {
  background-color: black;
  border-radius: 0px;
  color: white;
}
.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .loginSection {
    width: 90%;
    background-color: #f1d0be;
    padding: 50px 10px;
  }
}
</style>